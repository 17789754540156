import Arrows from '../../assets/arrows.png'
import './hero.css'

function Hero () {
    return (
        <div className='flex'>
            <div className="text-left ml-5 mt-10 md:ml-20">
                <h1 className="font-bold text-white text-6xl">Web3 Tool Explorer</h1>
                <h1 className="font-bold text-fuchsia-600 text-6xl">Build Your Stack</h1>
                <h5 className="text-white text-lg font-thin mt-8">Find the industry standard software tools available for your project.</h5>
                <h3 className="font-bold text-fuchsia-400 mt-20 text-2xl">What are you looking for?</h3>
            </div>
            <div id="fadeshow">
                <img style={{height: 350, width: 350, opacity: .8}} className="ml-10 mt-10" src={Arrows} alt="double arrow" />
            </div>
        </div>
    );
}

export default Hero;