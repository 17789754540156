import GuavaLogo from '../../assets/logos/guava.png'

function Footer () {
    return (
        <div className="flex flex-row justify-center text-white mt-20 mb-8">
            <p className="font-thin">brought to you by the guava team</p>
            <img src={GuavaLogo} style={{height: '30px', width: '30px'}} alt="guava logo"/>
        </div>
    )
}

export default Footer;