import './App.css';
import Header from './components/header/header.tsx';
import Hero from './components/hero/hero.tsx';
import Footer from './components/footer/footer.tsx';
import Main from './components/main/main.tsx';

function App() {
  return (
    <div className="App">
        <Header />
        <Hero />
        <Main />
        <Footer />
    </div>
  );
}

export default App;
