import React from 'react'

class Filters extends React.Component {

    handleClick = (e) => {
       // @ts-ignore
        this.props.onTagSelection(e.target.value)
    }

    render () {
        const filters = ['All', 'Charity', 'DAO', 'Development', 'Education', 'Funding', 'Gaming', 'Governance', 'Investing', 'Job Boards', 'Legal', 'NFT', 'Payroll Management', 'Security Audits', 'Social', 'Token', 'Treasury']
        return (
            <div className="md:m-20">{filters.map((filter) => {
                return <button 
                className="text-white p-2 m-3 bg-fuchsia-600 rounded font-bold"
                key={filter}
                value={filter}
                onClick={this.handleClick}>{filter}</button>
            })}</div>
        );
    }

}

export default Filters;