import './header.css'
import GuavaLogo from '../../assets/logos/guava.png'

function Header () {
    return (
        <ul>
            <li className='flex'>
                <img style={{height: '30px', width: '30px', marginTop: '10px'}} src={GuavaLogo} alt="guava logo" />
                <a className='font-bold' href="https://guavatech.io/">Guava Tech</a>
            </li>
            <li style={{float: 'right'}}>
                <a className="font-bold underline" href="https://twitter.com/guavatech_io" target="_blank" rel="noreferrer">Follow Us</a>
            </li>
            <li style={{float: 'right'}}>
                <a className="active font-bold" href="https://guavatech.io/">Consulting</a>
            </li>
        </ul>
    );
}

export default Header;