// @ts-ignore
import Tools from '../tools/tools.tsx';
// @ts-ignore
import Filters from '../filters/filters.tsx';
import React from 'react';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {selectedTags: ['All']};
    }

    handleTagSelection = (selectedTags) => {
        this.setState({selectedTags: [selectedTags]});
    }

    render() {
        // @ts-ignore
        const selectedTags = this.state.selectedTags;
        return ( 
            <div className="w-full">
                <div>
                    <Filters onTagSelection={this.handleTagSelection} />
                </div>
                <div>
                    <Tools selectedTags={selectedTags} />
                </div>
            </div>
        );
    }
}

export default Main;