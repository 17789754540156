import React from 'react';
import { Card } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { toolsData } from '../../data/data';
// @ts-ignore
import { Tags } from '../tags/tags.tsx';

function Tools (props) {

    return (<div className="flex flex-row flex-wrap justify-center">
      {toolsData.map((tool) => {
        const found = tool.tags.some(r=> props.selectedTags.indexOf(r) >= 0) || props.selectedTags.includes('All')
        if (found) {
          return <Card
          key={tool.name}
          style={{ width: 300 }}
          className="bg-slate-300 rounded-lg p-5 m-5"
          cover={
            <img
              alt="example"
              className='drop-shadow-xl'
              style={{height: 100, width: 100, marginLeft: '30%', marginBottom: '5px'}}
              src={require(`../../assets/logos/${tool.logoPath}`)}
            />
          }
        >
          <a href={tool.url} target="_blank" rel="noreferrer">
            <Meta
              style={{color: 'black', fontWeight: 'bold', textDecoration: 'underline'}}
              title={tool.name}
            />
          </a>
          <div>{tool.description}</div>
          <Tags tags={tool.tags}/>
        </Card>
        } else {
          return null
        }
      })}
    </div>
    );
}

export default Tools;